import autoBind      from 'react-autobind';
import classNames    from 'classnames';
import React         from 'react';
import update        from 'immutability-helper';
import { Input }     from 'reactstrap';

import TaskActions        from '~/actions/task_actions';
import LeadsSelect        from '~/components/forms/leads_select';
import TaskCategorySelect from '~/components/forms/task_category_select';
import TaskPrioritySelect from '~/components/forms/task_priority_select';
import TaskStatusSelect   from '~/components/forms/task_status_select';
import TaskEditor         from '~/components/forms/HtmlEditors/TaskEditor';
import FollowUpPicker     from '~/components/forms/lead_fields/follow_up_picker';
import { TeammatePicker } from '~/components/forms/team_member_picker';

class AddTaskForm extends React.Component {
  constructor(props) {
    super(props);

    const { lead } = this.props;

    this.state = {
      task: {
        name:        'Follow Up',
        category:    'follow_up',
        due_date_at: Moment().format('YYYY-MM-DD'),
        description: '',
        owner_id:    Rails.helpers.currentUser.id,
        priority:    'normal',
        status:      'to_do',
        lead_ids:    [lead.id],
      },
      errors:  {},
      sending: false,
    };

    autoBind(this);
  }

  setTaskField(name, val) {
    this.setState({
      task: update(this.state.task, { [name]: { $set: val } }),
    });
  }

  onFormSubmit = (e) => {
    e.preventDefault();

    const { task } = this.state;
    const { lead } = this.props;

    this.setState({ sending: true });

    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      TaskActions.createTask(task, lead.id);
    } else {
      this.setState({ sending: false, errors });
    }
  };

  validate() {
    const { task } = this.state;
    const errors = {};

    if (!task.name) {
      errors.name = "Can't be empty";
    }

    if (!task.category) {
      errors.category = "Can't be empty";
    }

    if (!task.due_date_at) {
      errors.due_date_at = "Can't be empty";
    }

    if (!task.priority) {
      errors.priority = "Can't be empty";
    }

    if (!task.status) {
      errors.status = "Can't be empty";
    }

    if (_lodash.isEmpty(task.lead_ids)) {
      errors.lead_ids = "Can't be empty";
    }

    this.setState({ errors });

    return errors;
  }

  render() {
    const { task, sending, errors } = this.state;
    const submitButtonClass = classNames('btn btn-primary', {
      disabled: sending,
    });

    return (
      <form onSubmit={this.onFormSubmit}>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="task_name">Task Name</label>
            <Input
              id="task_name"
              name="name"
              className={classNames(
                'd-block',
                errors.name ? 'has-error' : '',
              )}
              placeholder="Enter Name"
              value={task.name}
              onChange={(val) => this.setTaskField('name', val && val.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputCategory">Task Category</label>
            <TaskCategorySelect
              id="inputCategory"
              name="category"
              placeholder="Select Category"
              value={task.category}
              className={errors.category ? 'has-error' : ''}
              onChange={(opt) => this.setTaskField('category', opt && opt.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="task_due_date_at">Due Date</label>
            <FollowUpPicker
              id="task_due_date_at"
              ref={(el) => {
                this.picker = el;
              }}
              placeholder="Select Due Date"
              className={classNames({
                'has-error': errors.due_date_at,
              })}
              right
              disabledDays={[{ before: Moment().toDate() }]}
              date={task.due_date_at}
              onChange={(picker) => this.setTaskField(
                'due_date_at',
                picker && picker.date,
              )}
            />
          </div>
        </div>

        <div className="form-group mb15">
          <label htmlFor="task-description" className="d-block">Description</label>
          <TaskEditor
            className={errors.description ? 'tiny-mce has-error' : ''}
            onChange={(html) => this.setTaskField('description', html)}
            placeholder="Enter Description"
          />
        </div>

        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="leads-tasks" className="d-block">Related Contacts</label>
            <LeadsSelect
              id="leads-tasks"
              placeholder="Please Select Contacts ..."
              selected_ids={task.lead_ids}
              multiple
              onChange={(ids) => this.setTaskField('lead_ids', ids)}
              isInvalid={!!errors.lead_ids}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label
              htmlFor="task_owner_id"
              className="form-control-label"
            >
              Owner
            </label>
            <TeammatePicker
              id="task_owner_id"
              name="owner_id"
              placeholder="Owner"
              value={task.owner_id}
              onChange={(opt) => this.setTaskField('owner_id', opt && opt.value)}
              clearable
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="task_priority">Priority</label>
            <TaskPrioritySelect
              id="task_priority"
              name="priority"
              placeholder="Select Priority"
              value={task.priority}
              className={errors.priority ? 'has-error' : ''}
              onChange={(opt) => this.setTaskField('priority', opt && opt.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="task_status">Status</label>
            <TaskStatusSelect
              id="task_status"
              name="status"
              placeholder="Select Status"
              value={task.status}
              className={errors.status ? 'has-error' : ''}
              onChange={(opt) => this.setTaskField('status', opt && opt.value)}
            />
          </div>
        </div>

        <div className="form-group pt10">
          <div className="pull-right">
            {sending ? (
              <button
                type="button"
                className={submitButtonClass}
                disabled={sending}
              >
                Creating...
              </button>
            ) : (
              <button type="submit" className={submitButtonClass}>
                Add Task
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default AddTaskForm;
