import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TaskActions from '~/actions/task_actions';
import FollowUpPicker from '~/components/forms/lead_fields/follow_up_picker';
import AgentAlertTask from '../AgentAlertTask';
import Buttons from './Buttons';
import { formatValue, formatPrice, formatUSDate } from '~/helpers/formatHelper';

const AgentAlertModal = ({
  lead, agentAlert, showModal, handleCloseModal,
}) => {
  const { currentUser } = Rails.helpers;
  const [isOpen, setIsOpen] = useState(false);
  const [task, setTask] = useState({
    isOpen:      false,
    name:        'Follow Up',
    category:    'follow_up',
    due_date_at: null,
    description: '',
    owner_id:    currentUser.id,
    priority:    'normal',
    status:      'to_do',
    lead_ids:    [lead.id],
  });
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const pickerRef = useRef(null);

  const onCollapseClick = (e) => {
    e.preventDefault();

    setIsOpen(!isOpen);
  };

  const validate = () => {
    const validationErrors = {};

    if (!task.due_date_at) {
      validationErrors.due_date_at = 'Must be set to a date';
    }

    if (!task.name) {
      validationErrors.name = "Can't be empty";
    }

    if (!task.category) {
      validationErrors.category = "Can't be empty";
    }

    if (!task.priority) {
      validationErrors.priority = "Can't be empty";
    }

    if (!task.status) {
      validationErrors.status = "Can't be empty";
    }

    if (isEmpty(task.lead_ids)) {
      validationErrors.lead_ids = "Can't be empty";
    }

    if (task.status === 'onboarding' && isEmpty(lead.email)) {
      validationErrors.status = 'Please add an email address before moving this lead to Signed.';
    }

    setErrors(validationErrors);

    return isEmpty(validationErrors);
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);

      TaskActions.createTask(task, lead.id);

      handleCloseModal();
      setIsSaving(false);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      size="lg"
      centered
      backdrop={false}
      className="modal-overlay"
      enforceFocus={false}
    >
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center">
          <h5>
            {lead.name}
            {' '}
            {formatUSDate(agentAlert.alert_date)}
          </h5>

          <a onClick={handleCloseModal}>
            <FontAwesomeIcon icon={['far', 'fa-times']} />
          </a>
        </div>

        <div className="alert alert-primary text-left mt-3">
          <p>
            <strong>Alert Type:</strong>
            {' '}
            {formatValue(agentAlert.alert_type)}
          </p>
          <p>
            <strong>Description:</strong>
            {' '}
            {formatValue(agentAlert.alert_description)}
          </p>
        </div>

        <div className="d-flex mt-3">
          <div className="col border rounded mr-3 p-3">
            <p>
              <strong>Alert ID:</strong>
              {' '}
              {formatValue(agentAlert.alert_guid)}
            </p>
            <p>
              <strong>Agent ID:</strong>
              {' '}
              {formatValue(agentAlert.agent_id)}
            </p>

            <p>
              <strong>Role:</strong>
              {' '}
              {formatValue(agentAlert.role)}
            </p>

            <p>
              <strong>Office Name:</strong>
              {' '}
              {formatValue(agentAlert.client_name)}
            </p>

            <p>
              <strong>Office ID:</strong>
              {' '}
              {formatValue(agentAlert.office_guid)}
            </p>

            <p>
              <strong>Address:</strong>
              {' '}
              {formatValue(agentAlert.unparsed_address)}
            </p>

            <p>
              <strong>City:</strong>
              {' '}
              {formatValue(agentAlert.postal_city)}
            </p>

            <p>
              <strong>State:</strong>
              {' '}
              {formatValue(agentAlert.state)}
            </p>

            <p>
              <strong>Postal Code:</strong>
              {' '}
              {formatValue(agentAlert.postal_code)}
            </p>
          </div>

          <div className="col border rounded p-3">
            <p>
              <strong>MLS ID:</strong>
              {' '}
              {formatValue(agentAlert.reso_ouid)}
            </p>

            <p>
              <strong>MLS Name:</strong>
              {' '}
              {formatValue(agentAlert.mls?.name)}
            </p>

            <p>
              <strong>Listing ID:</strong>
              {' '}
              {formatValue(agentAlert.listing_key)}
            </p>

            <p>
              <strong>Property Type:</strong>
              {' '}
              {formatValue(agentAlert.property_type)}
            </p>

            <p>
              <strong>Prop Sub Type:</strong>
              {' '}
              {formatValue(agentAlert.property_subtype)}
            </p>

            <p>
              <strong>Price:</strong>
              {' '}
              {formatPrice(agentAlert.price)}
            </p>
          </div>
        </div>

        <div className="d-flex border rounded mt-3 py-3">
          <div className="col mr-3">
            <p>
              <strong>Cobroke ID:</strong>
              {' '}
              {formatValue(agentAlert.cobroke_agent_id)}
            </p>

            <p>
              <strong>Cobroke Name:</strong>
              {' '}
              {formatValue(agentAlert.cobroke_agent_name)}
            </p>
          </div>

          <div className="col">
            <p>
              <strong>Cobroke Office ID:</strong>
              {' '}
              {formatValue(agentAlert.cobroke_office_guid)}
            </p>

            <p>
              <strong>Cobroke Office Name:</strong>
              {' '}
              {formatValue(agentAlert.cobroke_office_name)}
            </p>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-start p0">
              <div className="col col-md-4 p0">
                <div>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-outline-info" onClick={onCollapseClick}>
                        {isOpen ? (
                          <FontAwesomeIcon icon={['far', 'fa-minus']} />
                        ) : (
                          <FontAwesomeIcon icon={['far', 'fa-plus']} />
                        )}
                      </button>
                    </div>

                    <FollowUpPicker
                      ref={pickerRef}
                      placeholder="Follow Up Task"
                      lead
                      date={task.due_date_at}
                      className={classNames({ 'has-error': errors.due_date_at })}
                      onChange={(picker) => setTask({ ...task, due_date_at: picker && picker.date })}
                      right
                    />
                  </div>
                  <p className="pt3">Select date for Follow Up</p>
                </div>
              </div>

              {!isOpen && (
                <Buttons
                  handleCloseModal={handleCloseModal}
                  handleConfirm={handleConfirm}
                  isSaving={isSaving}
                />
              )}
            </div>
          </div>

          <AgentAlertTask
            isOpen={isOpen}
            task={task}
            isSaving={isSaving}
            errors={errors}
            setTask={setTask}
            handleCloseModal={handleCloseModal}
            handleConfirm={handleConfirm}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

AgentAlertModal.propTypes = {
  lead:             PropTypes.shape({}).isRequired,
  agentAlert:       PropTypes.shape({}).isRequired,
  showModal:        PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default AgentAlertModal;
