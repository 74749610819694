import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AgentAlertPopover = ({ children, placement }) => {
  const [showPopover, setShowPopover] = useState(false);
  const [popoverTarget, setPopoverTarget] = useState(null);

  const togglePopover = (event) => {
    setShowPopover(true);
    setPopoverTarget(event.target);
  };

  return (
    <>
      {children({ togglePopover })}

      <Overlay
        show={showPopover}
        target={popoverTarget}
        placement={placement}
        containerPadding={40}
        onHide={() => setShowPopover(false)}
        flip
      >
        <Popover id="popover-agent-alerts-panel" className="border-0">
          <button
            type="button"
            className="close position-absolute"
            onClick={() => setShowPopover(false)}
            style={{
              top: '10px', right: '10px', fontSize: '1.2rem', border: 'none', background: 'none',
            }}
          >
            <FontAwesomeIcon icon={['fas', 'times']} size="sm" />
          </button>

          <Popover.Content className="text-white bg-green-lighter rounded">
            <h3>Upgrade your Subscription</h3>
            <p className="text-dark mb-3">
              Stay up to date on potential contacts with Agent Alerts
            </p>

            <a
              href="/billing"
              target="_blank"
              className="btn btn-success"
            >
              Upgrade your Subscription
            </a>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

AgentAlertPopover.propTypes = {
  children:  PropTypes.func.isRequired,
  placement: PropTypes.string,
};

AgentAlertPopover.defaultProps = {
  placement: 'auto',
};

export default AgentAlertPopover;
