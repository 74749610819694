export const formatPrice = (price) => {
  if (!price) return 'N/A';
  return new Intl.NumberFormat('en-US', {
    style:                 'currency',
    currency:              'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);
};

export const formatUSDate = (date) => {
  if (!date) return 'N/A';
  const parsedDate = new Date(date);
  return parsedDate.toLocaleDateString('en-US');
};

export const formatValue = (value) => value || 'N/A';
