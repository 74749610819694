import classNames                      from 'classnames';
import React, { Component, createRef } from 'react';
import { Link }                        from 'react-router-dom';
import PropTypes                       from 'prop-types';
import { Tooltip }                     from 'reactstrap';
import autoBind                        from 'react-autobind';
import ReactDOM                        from 'react-dom';
import { FontAwesomeIcon }             from '@fortawesome/react-fontawesome';
import {
  Popover,
  Button,
  OverlayTrigger,
}                                      from 'react-bootstrap';
import LeadHelpers                     from '~/helpers/lead_helpers';
import brokerbit                       from '~/lib/brokerbit';
import LeadDrawerActions               from '~/actions/lead_drawer_actions';
import LeadDrawerStore                 from '~/stores/lead_drawer_store';
import TaskStore                       from '~/stores/task_store';
import APIRequest                      from '~/lib/api_request';
import ReassignLeadModal               from '~/components/modals/reassign_lead_modal';
import StatusSelect                    from '~/components/forms/dropdowns/StatusSelect';
import {
  BucketSelect,
  LeadBucketAndRoleSelect,
}                                      from '~/components/forms/lead_fields';
import {
  WarningMessage,
  checkGoogleScopes,
}                                      from '~/components/warnings/GooglePermissionDeniedWarning';

const team = Rails.helpers.currentTeam;
let leadStoreListener;
let taskStoreListener;

class LeadDrawerHeader extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);

    this.state = {
      tooltips: {
        call:  false,
        email: false,
        appt:  false,
      },
      errors:           {},
      warningMessage:   null,
      showEmailPopover: false,
      showApptPopover:  false,
    };
    this.ref = createRef();
    this.popoverTarget = createRef();
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  hideEmailPopover() {
    this.setState({ showEmailPopover: false });
  }

  hideApptPopover() {
    this.setState({ showApptPopover: false });
  }

  componentDidMount() {
    leadStoreListener = LeadDrawerStore.addListener(
      this.onStoreChange,
    );
    taskStoreListener = TaskStore.addListener(this.onStoreChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { leadDrawerStoreAction } = nextProps;

    const LeadDrawerStoreState = LeadDrawerStore.getState();
    const { errors } = LeadDrawerStoreState;

    if (leadDrawerStoreAction === 'updateLeadFail' && errors) {
      this.setState({ errors });
    } else {
      this.setState({ errors: {} });
    }
  }

  componentDidUpdate(prevProps) {
    const { lead } = this.props;

    // Check if there's a change in the lead status
    if (lead && prevProps.lead && (lead.recruiting !== prevProps.lead.recruiting || lead.retention !== prevProps.lead.retention)) {
      this.handleLeadStatusChange();
    }
  }

  componentWillUnmount() {
    if (leadStoreListener) leadStoreListener.remove();
    if (taskStoreListener) taskStoreListener.remove();
  }

  handleLeadStatusChange() {
    const { lead } = this.props;
    let url = '';

    // Determine the URL based on some condition
    if (GlobalContainer.product() === 'retention' && lead.recruiting) {
      url = `/recruiter/today?lead=${lead.id}&_t=${lead.team_id}`;
    } else if (GlobalContainer.product() === 'recruiting' && lead.retention) {
      url = `/retention/today?lead=${lead.id}&_t=${lead.team_id}`;
    }

    // Defer the URL update
    if (url) {
      setTimeout(() => {
        browserHistory.push(url);
      }, 0); // SetTimeout with 0 delay to defer the execution until the stack is clear
    }
  }

  handleReassignLeadClick(e) {
    e.preventDefault();

    const { lead } = this.props;

    ReactDOM.render(
      <ReassignLeadModal
        teamID={team.id}
        leadID={lead.id}
        organizationID={team.organization_id}
      />,
      document.getElementById('secondary-modal'),
    );
  }

  handleSwitchForm(formName) {
    const { switchForm } = this.props;
    if (switchForm) switchForm(formName);
  }

  onStoreChange() {
    const { leadDrawerStoreAction, recordIndex } = LeadDrawerStore.getState();
    const { lastTaskStoreAction } = TaskStore.getState();
    const { lead, switchForm } = this.props;

    // deselect form if done with action
    if (
      leadDrawerStoreAction === 'createLeadEmailDone'
      || leadDrawerStoreAction === 'createLeadAppointmentDone'
      || leadDrawerStoreAction === 'createLeadSmsMessageDone'
      || leadDrawerStoreAction === 'createLeadActivityDone'
      || leadDrawerStoreAction === 'updateLeadActivityDone'
      || lastTaskStoreAction === 'createTaskDone'
      || leadDrawerStoreAction === 'updateLeadEmailDone'
      || leadDrawerStoreAction === 'updateLeadSmsMessageDone'
    ) {
      switchForm(null);

      setTimeout(() => {
        LeadDrawerActions.loadLead(lead.id,  { recordIndex });
      });
    }
  }

  onStatusChange(opt) {
    if (!opt?.value) return;

    const { lead, query } = this.props;
    const opts = {
      id:     lead.id,
      status: opt.value,
      bucket: 'default',
    };

    LeadDrawerActions.updateLead(opts, query);
  }

  onUnArchiveClick(e) {
    e.preventDefault();

    const { lead, query } = this.props;

    APIRequest.get({
      resource: `/v1/leads/${lead.id}/users_limit_validation`,
    }).end((error, response) => {
      if (error) {
        const errors = response.body;
        this.setState({ errors });
      } else {
        LeadDrawerActions.updateLead(
          { id: lead.id, bucket: 'default' },
          query,
        );
      }
    });
  }

  onChangeSnoozeClick(e) {
    e.preventDefault();
  }

  onMoveToSelect(bucket) {
    const { lead, query } = this.props;

    switch (bucket.value) {
      case 'recruiting':
        brokerbit.confirmBox({
          message:  `Are you sure you want to send ${lead.name} back to recruiting and remove their access to Brokerkit?`,
          callback: (ok) => {
            if (ok) {
              LeadDrawerActions.updateLead(
                {
                  id:     lead.id,
                  status: 'new_lead',
                  bucket: 'backlog',
                },
                query,
              );
            }
          },
        });
        break;
      case 'agent':
        brokerbit.confirmBox({
          message:  `Are you sure you want to convert ${lead.name} to an Agent?`,
          callback: (ok) => {
            if (ok) {
              LeadDrawerActions.updateLeadRole(lead, 'agent');
            }
          },
        });
        break;
      case 'affiliate':
        brokerbit.confirmBox({
          message:  `Are you sure you want to convert ${lead.name} to an Affiliate?`,
          callback: (ok) => {
            if (ok) {
              LeadDrawerActions.updateLeadRole(lead, 'affiliate');
            }
          },
        });
        break;
      default:
        LeadDrawerActions.updateLead(
          { id: lead.id, bucket: bucket.value },
          query,
        );
        break;
    }
  }

  getAlertMessage(message, url, linkText) {
    return (
      <div className="alert alert-warning ml15 mr15 mb15">
        {message}
        <Link to={url} className="ml10">
          {linkText}
        </Link>
      </div>
    );
  }

  getLimitAlert() {
    const { errors } = this.state;

    return errors?.reached_users_limit ? (
      <div className="alert alert-danger ml15 mr15 mb15">
        You are unable to add another user with the Admin or Staff
        role at this time as you are at the current limit of your
        pricing plan. Please email us at
        <a
          href="mailto:help@getbrokerkit.com"
          target="_blank"
          rel="noreferrer"
        >
          help@getbrokerkit.com
        </a>
        {' '}
        to upgrade your pricing plan to enable additional users.
      </div>
    ) : null;
  }

  getArchivedAlert() {
    const { lead } = this.props;

    return lead.archived ? (
      <div>
        {this.getLimitAlert()}

        <div className="alert alert-danger ml15 mr15 mb15">
          This lead has been archived and will be hidden everywhere
          except Archived.
          {Rails.abilities.manageLead && (
            <a
              href="#"
              onClick={this.onUnArchiveClick}
              className="ml10"
            >
              Un-Archive
            </a>
          )}
        </div>
      </div>
    ) : null;
  }

  renderAlert() {
    return this.getArchivedAlert() || null;
  }

  renderCallButton() {
    const { lead, handleOpenDialer } = this.props;
    const { tooltips } = this.state;

    if (!Rails.abilities.manageLead) return null;

    return (
      <>
        {lead.phone || lead.other_phone || lead.office_phone ? (
          <div className="btn-group" role="group">
            <button
              id="btn-call"
              type="button"
              className="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FontAwesomeIcon
                icon={['fal', 'fa-phone-alt']}
                size="sm"
                className="mr5"
              />
              {' '}
              Call
            </button>
            <div className="dropdown-menu" aria-labelledby="btn-call">
              {lead.phone && (
                <a
                  className="dropdown-item"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenDialer(lead.phone);
                  }}
                >
                  Cell Phone
                </a>
              )}

              {lead.other_phone && (
                <a
                  className="dropdown-item"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenDialer(lead.other_phone);
                  }}
                >
                  Other Phone
                </a>
              )}

              {lead.office_phone && (
                <a
                  className="dropdown-item"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenDialer(lead.office_phone);
                  }}
                >
                  Office Phone
                </a>
              )}
            </div>
          </div>
        ) : (
          <a
            id="btn-call"
            tabIndex="-1"
            className="btn btn-secondary"
            onMouseEnter={() => {
              this.setState({ tooltips: { call: true } });
            }}
            onMouseLeave={() => {
              this.setState({ tooltips: { call: false } });
            }}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <FontAwesomeIcon
              icon={['fal', 'fa-phone-alt']}
              size="sm"
              className="mr5"
            />
            {' '}
            Call
          </a>
        )}
        <Tooltip
          placement="top"
          isOpen={tooltips.call}
          target="btn-call"
        >
          Contact&apos;s phone required
        </Tooltip>
      </>
    );
  }

  renderPopover(props) {
    const { warningMessage, showEmailPopover } = this.state;
    const closeHandler = showEmailPopover ? this.hideEmailPopover : this.hideApptPopover;

    return (
      <Popover id="dropdown-message-id" {...props}>
        <Popover.Content className="bg-grey-lightest">
          <WarningMessage
            message={warningMessage}
            onClose={closeHandler}
          />
        </Popover.Content>
      </Popover>
    );
  }

  async handleButtonClick(buttonText, e) {
    e.preventDefault();
    const { currentUser } = Rails.helpers;
    const emailWarningMessage = 'To send emails through the contact profile, please use a Google or Microsoft hosted email address and sign in with Google or Microsoft on the sign-in page. Note that bulk, blast, and campaign emails will still work.';
    const apptWarningMessage = 'To send appointments through the contact profile, please use a Google or Microsoft hosted email address and sign in with Google or Microsoft on the sign-in page.';

    if (currentUser.is_google) {
      const { error, message } = await checkGoogleScopes(buttonText);
      if (!error) {
        if (buttonText.toLowerCase() === 'email') {
          this.setState({ showEmailPopover: false, showApptPopover: false }, () => {
            this.handleSwitchForm(buttonText.toLowerCase());
          });
        } else if (buttonText.toLowerCase() === 'appointment') {
          this.setState({ showEmailPopover: false, showApptPopover: false }, () => {
            this.handleSwitchForm(buttonText.toLowerCase());
          });
        }
      } else if (buttonText.toLowerCase() === 'email') {
        this.setState({ warningMessage: message, showEmailPopover: true, showApptPopover: false });
      } else if (buttonText.toLowerCase() === 'appointment') {
        this.setState({ warningMessage: message, showApptPopover: true, showEmailPopover: false });
      }
    } else if (!currentUser.is_microsoft) {
      if (buttonText.toLowerCase() === 'email') {
        this.setState({ warningMessage: emailWarningMessage, showEmailPopover: true, showApptPopover: false });
      } else if (buttonText.toLowerCase() === 'appointment') {
        this.setState({ warningMessage: apptWarningMessage, showEmailPopover: false, showApptPopover: true });
      }
    } else {
      this.handleSwitchForm(buttonText.toLowerCase());
    }
  }

  renderMainActions() {
    const { lead, currentForm } = this.props;
    const {
      tooltips, warningMessage, showEmailPopover, showApptPopover,
    } = this.state;

    return (
      <div className="mr-4">
        <span className="mt5-sm-down mt15-lg-down mt0-xl-up">
          <span
            className="btn-group btn-group-selectable btn-log"
            role="group"
          >
            {lead.email ? (
              warningMessage ? (
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  container={this.ref.current}
                  show={showEmailPopover}
                  containerPadding={20}
                  overlay={this.renderPopover}
                >
                  <Button
                    id="btn-email"
                    className={`btn btn-secondary btn-active-blue ${brokerbit.isActive(currentForm, 'email')}`}
                    data-tooltip="Email"
                    onClick={(e) => this.handleButtonClick('Email', e)}
                    ref={this.popoverTarget}
                  >
                    <FontAwesomeIcon icon={['fal', 'fa-envelope']} size="sm" className="mr5" />
                    {' '}
                    Email
                  </Button>
                </OverlayTrigger>
              ) : (
                <a
                  href="#email"
                  id="btn-email"
                  tabIndex="-1"
                  className={`btn btn-secondary btn-active-blue ${brokerbit.isActive(currentForm, 'email')}`}
                  data-tooltip="Email"
                  onClick={(e) => this.handleButtonClick('Email', e)}
                >
                  <FontAwesomeIcon icon={['fal', 'fa-envelope']} size="sm" className="mr5" />
                  {' '}
                  Email
                </a>
              )
            ) : (
              <a
                href="#email"
                id="btn-email"
                tabIndex="-1"
                className="btn btn-secondary"
                onMouseEnter={() => this.setState({ tooltips: { email: true } })}
                onMouseLeave={() => this.setState({ tooltips: { email: false } })}
                onClick={(e) => this.handleButtonClick('Email', e)}
              >
                <FontAwesomeIcon icon={['fal', 'fa-envelope']} size="sm" className="mr5" />
                {' '}
                Email
              </a>
            )}

            {this.renderCallButton()}

            {lead.phone ? (
              <a
                href="#text-message"
                id="btn-text-message"
                tabIndex="-1"
                className={`btn btn-secondary btn-active-yellow ${brokerbit.isActive(
                  currentForm,
                  'text-message',
                )}`}
                data-tooltip="Text"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleSwitchForm('text-message');
                }}
              >
                <FontAwesomeIcon
                  icon={['fal', 'fa-comment']}
                  size="sm"
                  className="mr5"
                />
                {' '}
                Text
              </a>
            ) : (
              <a
                href="#text-message"
                id="btn-text-message"
                tabIndex="-1"
                className="btn btn-secondary"
                onMouseEnter={() => {
                  this.setState({ tooltips: { texting: true } });
                }}
                onMouseLeave={() => {
                  this.setState({ tooltips: { texting: false } });
                }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <FontAwesomeIcon
                  icon={['fal', 'fa-comment']}
                  size="sm"
                  className="mr5"
                />
                {' '}
                Text
              </a>
            )}

            {GlobalContainer.product() !== 'retention' && (
              warningMessage ? (
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  container={this.ref.current}
                  show={showApptPopover}
                  containerPadding={20}
                  overlay={this.renderPopover}
                >
                  <Button
                    id="btn-appt"
                    className={`btn btn-secondary btn-active-purple ${brokerbit.isActive(currentForm, 'appointment')}`}
                    data-tooltip="Appointment"
                    onClick={(e) => this.handleButtonClick('appointment', e)}
                  >
                    <FontAwesomeIcon icon={['fal', 'fa-calendar-alt']} size="sm" className="mr5" />
                    {' '}
                    Appt
                  </Button>
                </OverlayTrigger>
              ) : (
                <a
                  href="#appointment"
                  id="btn-appt"
                  tabIndex="-1"
                  className={`btn btn-secondary btn-active-purple ${brokerbit.isActive(currentForm, 'appointment')}`}
                  data-tooltip="Appointment"
                  onClick={(e) => this.handleButtonClick('appointment', e)}
                >
                  <FontAwesomeIcon icon={['fal', 'fa-calendar-alt']} size="sm" className="mr5" />
                  {' '}
                  Appt
                </a>
              )
            )}
          </span>
          <Tooltip
            placement="top"
            isOpen={tooltips.texting}
            target="btn-text-message"
          >
            Contact&apos;s phone required
          </Tooltip>
          <Tooltip
            placement="top"
            isOpen={tooltips.email}
            target="btn-email"
          >
            Contact&apos;s email required
          </Tooltip>
        </span>

        {GlobalContainer.product() !== 'retention' && (
          <a
            href="#log-appointment"
            tabIndex="-1"
            className={`ml10-sm-up mt15-xs-down btn btn-secondary btn-active-green ${brokerbit.isActive(
              currentForm,
              'log-appointment',
            )}`}
            onClick={(e) => {
              e.preventDefault();
              this.handleSwitchForm('log-appointment');
            }}
          >
            <FontAwesomeIcon
              icon={['far', 'fa-check']}
              size="sm"
              className="mr5"
            />
            {' '}
            Log Appt
          </a>
        )}

        <a
          href="#add-task"
          tabIndex="-1"
          className={`ml10 mt15-xs-down btn btn-secondary btn-active-green ${brokerbit.isActive(
            currentForm,
            'add-task',
          )}`}
          onClick={(e) => {
            e.preventDefault();
            this.handleSwitchForm('add-task');
          }}
        >
          <FontAwesomeIcon
            icon={['far', 'fa-tasks']}
            size="sm"
            className="mr5"
          />
          {' '}
          Task
        </a>
      </div>
    );
  }

  renderLeadActions() {
    const { lead, query, currentForm } = this.props;

    const role = lead.team_member && lead.team_member.role;
    const isRetention = GlobalContainer.product() === 'retention';

    return (
      <div className="mt15-lg-down">
        <div className="text-right pr0 pl5-sm-up mt5-xs-down">
          {!currentForm && !isRetention && (
            <BucketSelect
              placeholder="Move to ..."
              className="text-left"
              onChange={this.onMoveToSelect}
            />
          )}

          {!currentForm && isRetention && (
            <LeadBucketAndRoleSelect
              role={role}
              lead={lead}
              className="text-left"
              onChange={this.onMoveToSelect}
            />
          )}
        </div>
      </div>
    );
  }

  renderNoteActions() {
    const { currentForm } = this.props;

    return (
      <div className="mt5-lg-down">
        <label htmlFor="note-button" className="label font-weight-bold mr5">NOTE:</label>
        <div
          className="btn-group btn-group-selectable btn-log"
          role="group"
        >
          <a
            href="#phone-call"
            tabIndex="-1"
            className={`btn btn-secondary btn-active-pink ${brokerbit.isActive(
              currentForm,
              'phone-call',
            )}`}
            data-tooltip="Phone Call"
            onClick={(e) => {
              e.preventDefault();
              this.handleSwitchForm('phone-call');
            }}
          >
            <FontAwesomeIcon icon={['fal', 'fa-phone-alt']} />
          </a>
          <a
            href="#log-text-message"
            tabIndex="-1"
            className={`btn btn-secondary btn-active-yellow ${brokerbit.isActive(
              currentForm,
              'log-text-message',
            )}`}
            data-tooltip="Text Message"
            onClick={(e) => {
              e.preventDefault();
              this.handleSwitchForm('log-text-message');
            }}
          >
            <FontAwesomeIcon icon={['fal', 'fa-comment']} />
          </a>

          <a
            href="#email-sent"
            tabIndex="-1"
            className={`btn btn-secondary btn-active-blue ${brokerbit.isActive(
              currentForm,
              'email-sent',
            )}`}
            data-tooltip="Email Note"
            onClick={(e) => {
              e.preventDefault();
              this.handleSwitchForm('email-sent');
            }}
          >
            <FontAwesomeIcon icon={['fal', 'fa-envelope']} />
          </a>

          <a
            href="#general-note"
            tabIndex="-1"
            className={`btn btn-secondary btn-active-purple ${brokerbit.isActive(
              currentForm,
              'general-note',
            )}`}
            data-tooltip="General Note"
            onClick={(e) => {
              e.preventDefault();
              this.handleSwitchForm('general-note');
            }}
          >
            <FontAwesomeIcon icon={['fal', 'fa-sticky-note']} />
          </a>
        </div>
      </div>
    );
  }

  renderRoles() {
    const { lead } = this.props;

    if (!lead.team_member || !lead.team_member.role) return null;

    let className;
    switch (lead.team_member.role.name) {
      case 'admin':
        className = 'badge badge-outline-green';
        break;
      case 'staff':
        className = 'badge badge-outline-yellow';
        break;
      case 'agent':
        className = 'badge badge-outline-blue';
        break;
      case 'affiliate':
        className = 'badge badge-outline-gray';
        break;
      default:
        return null;
    }

    return (
      <small
        style={{ top: '-4px', position: 'relative' }}
        key={`role-${lead.team_member.role.id}`}
        className={classNames(className, 'ml-2')}
      >
        {lead.team_member.role.name.toUpperCase()}
      </small>
    );
  }

  renderStatus() {
    const { lead, currentForm } = this.props;
    const stage = LeadHelpers.determineStatusStage(lead);

    if (Rails.abilities.manageLead && !currentForm) {
      return (
        <StatusSelect
          stage={stage}
          key={`header-status-${lead.status}`}
          value={lead.status}
          onChange={this.onStatusChange}
          isSearchable
        />
      );
    }

    return null;
  }

  renderTaskAlert() {
    const { lead } = this.props;

    const contact = lead.recruiting ? 'lead' : 'team member';

    return (
      <div className="row">
        {lead.today_tasks && (
          <div className="col-sm alert alert-warning ml15 mr15 mb15">
            <span className="mr10">
              There is a task due for this
              {' '}
              {contact}
              {' '}
              today
            </span>
          </div>
        )}

        {lead.past_tasks && (
          <div className="col-sm alert alert-danger ml15 mr15 mb15">
            <span className="mr10">
              There is a task for this
              {' '}
              {contact}
              {' '}
              past the due date
            </span>
          </div>
        )}

        {lead.future_tasks && (
          <div className="col-sm alert alert-success ml15 mr15 mb15">
            <span className="mr10">
              This
              {' '}
              {contact}
              {' '}
              has a task due in the future
            </span>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { loadingLead, lead, currentForm } = this.props;
    const { errors } = this.state;

    if (!lead) {
      return <p>No lead loaded</p>;
    }

    let loadingSpinner;
    if (loadingLead) {
      loadingSpinner = (
        <FontAwesomeIcon
          icon="far fa-spinner"
          pulse
          className="ml-3"
        />
      );
    }

    let leadDrawerActions = (
      <div className="row bg-grey-lightest pt15 pb15 bt">
        <div className="order-1 col-md-12 col-xl-10 pr0-md-up">
          <div className="row" ref={this.ref}>
            {this.renderMainActions()}
            {this.renderNoteActions()}
          </div>
        </div>
        <div className="order-2 col-md-5 col-xl-2">
          {this.renderLeadActions()}
        </div>
      </div>
    );

    if (Rails.abilities.manageLead === false) {
      leadDrawerActions = (
        <div className="row bg-grey-lightest pt15 pb15 bt">
          <div className="order-1 col-md-12 col-xl-10 pr0-md-up">
            <div className="row">
              <div className="mt5-lg-down">
                <label htmlFor="general-note" className="label font-weight-bold mr5">NOTE:</label>
                <div
                  className="btn-group btn-group-selectable btn-log"
                  role="group"
                >
                  <a
                    href="#general-note"
                    tabIndex="-1"
                    className={`btn btn-secondary btn-active-purple ${brokerbit.isActive(
                      currentForm,
                      'general-note',
                    )}`}
                    data-tooltip="General Note"
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleSwitchForm('general-note');
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'fa-sticky-note']} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const showReassign = team
      && team.organization_id
      && lead.role_type !== 'internal'
      && Rails.abilities.reassign;

    return (
      <div className="bb">
        <div className="row pt15 pb15">
          <div className="col-sm-9">
            <h2 className="modal-title">
              <span className="mr-4">
                {LeadHelpers.renderLeadName(lead)}
                {this.renderRoles()}
                {loadingSpinner}
              </span>
              {showReassign && (
                <span>
                  <a
                    href="#"
                    onClick={this.handleReassignLeadClick}
                    className="btn btn-primary"
                  >
                    <FontAwesomeIcon icon={['far', 'fa-redo']} />
                    {' '}
                    Reassign
                  </a>
                </span>
              )}
            </h2>
          </div>
          <div className="col-sm-3 pull-right-sm-up">
            {this.renderStatus()}
          </div>
        </div>

        {errors.status && (
          <div
            className="alert alert-danger ml15 mr15 mb15"
            role="alert"
          >
            {errors.status}
          </div>
        )}

        {Rails.abilities.manageLead && this.renderAlert()}
        {Rails.abilities.manageLead && this.renderTaskAlert()}

        {leadDrawerActions}
      </div>
    );
  }
}

LeadDrawerHeader.defaultProps = {
  loadingLead:           false,
  leadDrawerStoreAction: null,
  lead:                  null,
  query:                 null,
  currentForm:           null,
  switchForm:            () => false,
  handleOpenDialer:      () => false,
};

LeadDrawerHeader.propTypes = {
  loadingLead:           PropTypes.bool,
  leadDrawerStoreAction: PropTypes.string,
  lead:                  PropTypes.shape({}),
  query:                 PropTypes.shape({}),
  currentForm:           PropTypes.string,
  switchForm:            PropTypes.func,
  handleOpenDialer:      PropTypes.func,
};

export default LeadDrawerHeader;
