import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse } from 'react-bootstrap';
import TaskCategorySelect from '~/components/forms/task_category_select';
import TaskPrioritySelect from '~/components/forms/task_priority_select';
import TaskStatusSelect from '~/components/forms/task_status_select';
import TaskEditor from '~/components/forms/HtmlEditors/TaskEditor';
import LeadsSelect from '~/components/forms/leads_select';
import { TeammatePicker } from '~/components/forms/team_member_picker';
import Buttons from './AgentAlertModal/Buttons';

const AgentAlertTask = ({
  isOpen,
  task,
  isSaving,
  errors,
  setTask,
  handleCloseModal,
  handleConfirm,
}) => (
  <Collapse in={isOpen} mountOnEnter unmountOnExit>
    <div className="row mt-3">
      <div className="col p0">
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="agent-alert-task-name">Task Name</label>
            <input
              id="agent-alert-task-name"
              name="name"
              value={task.name}
              className={classNames('d-block form-control', errors.name ? 'has-error' : '')}
              placeholder="Enter Name"
              onChange={(e) => setTask({ ...task, name: e && e.target.value })}
            />
          </div>

          <div className="form-group col-md-6">
            <label htmlFor="agent-alert-task-category">Task Category</label>
            <TaskCategorySelect
              id="agent-alert-task-category"
              name="category"
              placeholder="Select Category"
              value={task.category}
              className={errors.category ? 'has-error' : ''}
              onChange={(opt) => setTask({ ...task, category: opt && opt.value })}
            />
          </div>
        </div>

        <div className="form-group mb15">
          <label htmlFor="agent-alert-task-description" className="d-block">
            Description
          </label>

          <TaskEditor
            className={errors.description ? 'tiny-mce has-error' : ''}
            onChange={(html) => setTask({ ...task, description: html })}
            placeholder="Enter Description"
            id="agent-alert-task-description"
            spellCheck={isOpen}
          />
        </div>

        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="agent-alert-task-lead-ids" className="d-block">
              Related Contacts
            </label>

            <LeadsSelect
              id="agent-alert-task-lead-ids"
              placeholder="Please Select Contacts ..."
              selected_ids={task.lead_ids}
              multiple
              onChange={(ids) => setTask({ ...task, lead_ids: ids })}
              isInvalid={!!errors.lead_ids}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label
              htmlFor="agent-alert-task-owner-id"
              className="form-control-label"
            >
              Owner
            </label>

            <TeammatePicker
              id="agent-alert-task-owner-id"
              name="owner_id"
              placeholder="Owner"
              value={task.owner_id}
              onChange={(opt) => setTask({ ...task, owner_id: opt && opt.value })}
              clearable
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="agent-alert-task-priority">Priority</label>
            <TaskPrioritySelect
              id="agent-alert-task-priority"
              name="priority"
              placeholder="Select Priority"
              value={task.priority}
              className={errors.priority ? 'has-error' : ''}
              onChange={(opt) => setTask({ ...task, priority: opt && opt.value })}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="agent-alert-task-status">Status</label>
            <TaskStatusSelect
              id="agent-alert-task-status"
              name="status"
              placeholder="Select Status"
              value={task.status}
              className={errors.status ? 'has-error' : ''}
              onChange={(opt) => setTask({ ...task, status: opt && opt.value })}
            />
          </div>
        </div>

        {isOpen && (
          <Buttons
            handleCloseModal={handleCloseModal}
            handleConfirm={handleConfirm}
            isSaving={isSaving}
          />
        )}
      </div>
    </div>
  </Collapse>
);

AgentAlertTask.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  task:   PropTypes.shape({
    name:        PropTypes.string,
    category:    PropTypes.string,
    due_date_at: PropTypes.instanceOf(Date),
    description: PropTypes.string,
    owner_id:    PropTypes.number,
    priority:    PropTypes.string,
    status:      PropTypes.string,
    lead_ids:    PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  isSaving:         PropTypes.bool.isRequired,
  errors:           PropTypes.shape({}).isRequired,
  setTask:          PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleConfirm:    PropTypes.func.isRequired,
};

export default AgentAlertTask;
